<template>
	<div class="enterprise" style="padding-top: 16px;">
		<div class="breadcrumbTop">
			<div class="tips_top_box" style="display: flex;align-items: center;">
				<p><span style='color:red'>*</span> 工作地点是组织的地理位置，一般用于员工在劳动合同签订约定的工作所在地。</p>
				<div style="text-align: right;flex: 1;">
					<a-button type="primary" @click='visible=true'>添加工作地点
					</a-button>
					<a-button type="primary" style="margin-left: 12px;" @click='VisibleUpload=true'>批量添加工作地点
					</a-button>
					<a-button type="primary" style="margin-left: 12px;" @click='sortList'>调整排序
					</a-button>
				</div>
			</div>
			<div class="add_info">
				<div style="width:300px">
					<div class="FieldSettingsBox">
						<div class="FieldSettingsBoxTitle ">
							<span class="show_type">工作区域</span>
							<span class='cq_color show_type_setting' @click='go_clockCat'>设置</span>
						</div>
						<a-directory-tree :replaceFields="{children:'children', title:'name', key:'id' }"
							:tree-data="treeData" @select='selectItem' v-model:expandedKeys="expandedKeys"
							v-model:selectedKeys="selectedKeys">
						</a-directory-tree>
					</div>
				</div>
				<div style="flex: 3;margin-left: 12px;">

					<TableList :columns="columns" :where='where' :get_table_list="get_table_list" :slot_table_list="['op','icon']"
						ref="list">
						<template #op={record}>
							<div class="op">
								<span class="check" @click="getInfo(record)">编辑</span>
									<span style='color:#eee'> | </span>
								<span class="check" @click="meropen(record)">合并</span>
									<span style='color:#eee'> | </span>
								<a-popconfirm v-if='record.user_num==0' title="是否确认删除?" ok-text="是" cancel-text="否"
									@confirm="del(record)" @cancel="cancel">
									<span class="del"> 删除 </span>
								</a-popconfirm>
								<span v-else style="color:#999;">
									删除
								</span>
							</div>
						</template>
						<template #city={record}>
							<div>
								{{record.province}}/{{record.city}}/{{record.area}}
							</div>
						</template>
					</TableList>
				</div>
			</div>
		</div>
		<a-modal v-model:visible="visible" @cancel='cancelModel' :title="editType=='add'?'新增工作地点':'编辑工作地点'" @ok="addOk">
			<a-form :label-col="labelColEdit" :wrapper-col="wrapperColEdit">
				<a-form-item required label="工作地点" placeholder="请输入"
					:rules="[{ required: true, message: '工作地点名称不能为空',tagger:'blur' }]">
					<a-input v-model:value="formstatus.title" />
				</a-form-item>
				<a-form-item required label="所在城市" :rules="[{ required: true, message: '所在城市不能为空',tagger:'blur' }]">
					<a-cascader @change='changeAddress2' :options="options" v-model:value="addressLitst"
						:fieldNames="{ label: 'label', value: 'label', children: 'children' }" placeholder="请选择 省/市/区" />
				</a-form-item>
				<a-form-item label="所属区域">
					<div style='display: flex;align-items: center;'>
						<a-tree-select :replaceFields="{children:'children', label:'name', value: 'id' }"
							v-model:value="formstatus.category_id" show-search style="width: 90%;margin-right: 5px;"
							:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" placeholder="请选择上级组织"
							tree-default-expand-all :tree-data="chooseList">
						</a-tree-select>
						<a-icon type="plus-circle" @click='visible2=true' style='margin-left:6px;font-size: 24px;'  />
					</div>			
					
					<p>
						管理工作区域，<span class='cq_color' @click='go_clockCat'> 点击这里</span>
					</p>
				</a-form-item>
				<a-form-item label="详细地址">
					<a-textarea v-model:value="formstatus.address" />
				</a-form-item>
				<a-form-item label="邮政编码">
					<a-input v-model:value="formstatus.code" />
				</a-form-item>
			</a-form>
		</a-modal>

		<a-modal v-model:visible="visible2" :title="editaddType=='add'?'新增工作区域':'编辑工作区域'" @ok="addOk2">
			<a-form :label-col="labelColEdit" :wrapper-col="wrapperColEdit">
				<a-form-item required name='name' label="工作区域" :rules="[{ required: true, message: '工作区域不能为空',tagger:'blur' }]">
					<a-input v-model:value="formstatusadd.name" />
				</a-form-item>
				<a-form-item required label="上级工作区域">
					<a-tree-select  :replaceFields="{children:'children', label:'name', value: 'id' }"
						v-model:value="formstatusadd.pid" show-search style="width: 100%"
						:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" placeholder="请选择上级组织"
						tree-default-expand-all :tree-data="dataSource">
					</a-tree-select>
				</a-form-item>

				<a-form-item label="区域代码">
					<a-input v-model:value="formstatusadd.code" />
				</a-form-item>

			</a-form>
		</a-modal>
		<a-modal v-model:visible="mervisit" title="合并工作地点" @ok="merOk">
			<a-form :label-col="labelColEdit" :wrapper-col="wrapperColEdit">
				<a-form-item label="当前地点">
					{{merinfo.title}}
				</a-form-item>
				<a-form-item required label="合并入">
					<a-select  ref="select" v-model:value="merinfo.target_id">
						<a-select-option :key='index' :value="it.id" v-for='(it,index) in work_location_list'>{{it.title}}</a-select-option>
					</a-select>
				</a-form-item>
			</a-form>
		</a-modal>
		<a-modal :footer='null' width='50%' title='批量导入工作地点' v-model:visible="VisibleUpload" @ok="VisibleUpload=false">
			<uploadClock @success='success'></uploadClock>
		</a-modal>
	</div>
</template>

<script>
	import {
		mergeWorkLocation,
		getWorkLocationTree,
		removeWorkLocation,
		modifyWorkLocation,
		modifyWorkLocationCategory,
		workLocationCategory,
		removeWorkLocationCategory,
		workLocationSort,
		workLocation,workLocation2
	} from '@/api/organization.js'
	import options from './city.js'
	import uploadClock from './uploadClock.vue'
import TableList from "@/components/TableList";
	import {
		ruleList,
		removeRule,
		changeRule
	} from '@/api/attendance.js'
	import {
		message,
		TreeDataItem,
		TreeDragEvent,
		DropEvent,
		Modal
	} from 'ant-design-vue';


	export default {
		components: {
			TableList,
			uploadClock
		},
		data() {
			return {
				chooseList: [],
				merinfo: {},
				mervisit: false,
				VisibleUpload: false,
				editaddType: 'add',
				get_table_list: workLocation,
				dataSource: [],
				work_location_list:[],
				where: {
					category_id: 0,
				},
				expandedKeys: [],
				selectedKeys: [],
				treeData: [{
					name: '全部',
					id: 0,
					children: []
				}],
				addressLitst: [],
				options,
				search_form: false,
				name: '',
				addType: 'add',
				addTypeVisible: false,
				visible: false,
				visible2: false,
				formstatusadd: {},
				type_list: [],
				labelColEdit: {
					span: 4
				},
				wrapperColEdit: {
					span: 18
				},
				wrapperCol: {
					span: 12
				},
				select_list: [],
				type: '',
				formstatus: {},
				titelIndex: '',
				editType: 'add',
				columns: [{
						title: '工作地点',
						dataIndex: 'title',
						width: 110,
						ellipsis: true,
					},
					{
						title: '所在城市',
						dataIndex: 'city',
						width: 100,
						ellipsis: true,
						scopedSlots: {
							customRender: 'city'
						}
					},
					{
						title: '所属区域',
						dataIndex: 'category_name',
						width: 100,
						ellipsis: true,
					},
					{
						title: '详细地址',
						dataIndex: 'address',
						width: 130,
						ellipsis: true,
					},
					{
						title: '邮政编号',
						dataIndex: 'code',
						width: 100,
						
					},
					{
						title: '在职员工',
						dataIndex: 'user_num',
						width: 100,
					},
					{
						title: '操作',
						dataIndex: 'op',
						width: 160,
						scopedSlots: {
							customRender: "op"
						}
					},
				],
			}

		},
		created() {
			this.type = this.$route.query.type
			this.get_WorkLocationTree()
			this.get_list()
		},
		methods: {
			get_list(){
				workLocation2({data:{
					is_page:-1
				}}).then(res=>{
					this.work_location_list = res.data.list
				})
			},
			success() {
				this.VisibleUpload = false;
				this.$refs.list.get_list()
			},
			meropen(item) {
				this.mervisit = true
				this.merinfo = item
			},
			get_clock_list() {
				this.$refs.list.get_list()
			},
			merOk() {
				mergeWorkLocation({
					data: {
						source_id: this.merinfo.id,
						target_id: this.merinfo.target_id
					},
					info: true
				}).then(res => {
					this.mervisit = false
					this.merinfo = {}
						this.$refs.list.get_list()
				})
			},
			get_WorkLocationTree() {
				getWorkLocationTree().then(res => {
					this.dataSource = res.data.list
				})
				workLocationCategory().then(res => {
					this.treeData[0].children = res.data.list
					this.chooseList = JSON.parse(JSON.stringify(res.data.list))
				})
			},
			selectItem(indo, e) {
				this.where.category_id = indo[0]
				this.$refs.list.get_list()
			},
			go_clockCat() {
				this.$router.push('/organization/clockCat')
			},
			//调整排序
			sortList() {
				this.$router.push('/organization/sortList')
			},
			changeAddress2(e) {
				this.formstatus.province = e[0]
				this.formstatus.city = e[1]
				this.formstatus.area = e[2]
			},
			change_forbid(id) {
				changeCustomSettingStatus({
					data: {
						id: id
					}
				}).then(res => {
					message.success('操作成功');
					this.changeList(this.titelIndex)
				})
			},
			addOk() {
				modifyWorkLocation({
					data: {
						...this.formstatus
					}
				}).then(res => {
					message.success('操作成功');
					this.visible = false
					this.editType = 'add'
					this.formstatus = {}
					this.addressLitst = []
					this.$refs.list.get_list()
				})
			},
			getInfo(item) {
				this.formstatus = JSON.parse(JSON.stringify(item));
				this.editType = 'adit'
				this.addressLitst = [item.province, item.city, item.area]
				this.visible = true
			},
			onStart() {
				this.drag = true;
			},
			onEnd() {
				this.drag = false;
				let sort = []
				this.all_list.forEach(item => {
					sort.push(item.id)
				})
				sortCustomSetting({
					data: {
						sort: sort.join(',')
					}
				}).then(res => {
					message.success('操作成功');
					this.changeList(this.titelIndex)
				})
			},
			del(item) {
				removeWorkLocation({
					data: {
						id: item.id,
					}
				}).then(res => {
					this.$refs.list.get_list()
				})
			},
			changeList(type) {
				this.formstatus.pid = type
				getCustomSettingDetail({
					data: {
						type: this.type,
						pid: type,
					}
				}).then(res => {
					res.list.content.forEach(item => {
						item.forbid = item.status == 1 ? true : false
					})
					this.titelIndex = type
					this.all_list = res.list.content
				})
			},
			cancel(){
				
			},
			cancelModel() {
				this.visible2 = false
				this.formstatusadd = {}
				this.formstatus = {}
				this.editaddType = 'add'
				this.addressLitst = []
			},
			addOk2() {
				modifyWorkLocationCategory({
					data: {
						...this.formstatusadd
					}
				}).then(res => {
					this.formstatus = {}
					this.visible2 = false
					this.formstatusadd = {}
					this.editaddType = 'add'
					this.get_WorkLocationTree()
				})
			},
			getIndustry() {
				getCustomSettingCateSelect({
					data: {
						type: this.type
					}
				}).then(res => {
					this.type_list = res.list
					this.titelIndex = res.list[0].value
					this.formstatus.pid = res.list[0].value
					this.changeList(this.titelIndex)
				})
			},
			handle_submit() {

			}
		},

	}
</script>

<style lang="less" scoped>
	@import url("../../assets/less/app.less");

	@import 'info.less';

	.enterprise {
		background-color: #fff;
	}

	.breadcrumbTop {
		flex: 1;
	}

	.table_list_body {
		margin: 0;
		padding: 0;
	}

	.check {
		color: @cq_color;
		cursor: pointer;
	}

	.del {
		color: @cq_error;
	}

	.FieldSettingsBox {
		border: none;
		border-right: 1px solid @cq_line_e;
		margin-top: 12px;
		height: auto;
	}

	.FieldSettingsBoxTitle {
		display: flex;
		width: 100%;
		padding: 8px 12px;
		border-bottom: 1px solid @cq_line_e;

		span:nth-child(2) {
			flex: 4;
			margin-left: 8px;
		}

		span:nth-child(3) {
			flex: 1;
		}

		span:nth-child(4) {
			color: #fff;

		}
	}

	.FieldSettingsBoxHeader {
		background: #f5f8fa;
	}

	.FieldSettingsBoxTitle:hover {
		background: #f5f8fa;

		span:nth-child(4) {
			color: #666;
		}

	}

	.FieldSettingsBoxActive {
		border-right: 3px solid @cq_color;
		background: #f8f8f9;
	}

	.add_info {
		margin-top: 12px;
		display: flex;
		// padding: 12px;
	}

	.show_type {
		border-left: 3px solid #0079FF;
		padding-left: 12px;
		width: 70%;
	}

	.show_type_setting {
		width: 20%;
		text-align: right;
	}

	.w100 {
		width: 100px;
		text-align: left;
	}

	.table_list_body {
		margin-top: -12px;
	}

	/deep/.ant-tree.ant-tree-directory>li span.ant-tree-node-content-wrapper::before,
	/deep/.ant-tree.ant-tree-directory .ant-tree-child-tree>li span.ant-tree-node-content-wrapper::before,
	/deep/.ant-tree li .ant-tree-node-content-wrapper,
	/deep/.ant-tree li span.ant-tree-switcher,
	/deep/.ant-tree li span.ant-tree-iconEle {
		height: 32px;
		line-height: 32px;
		padding-top: 2px;
	}

	/deep/.ant-tree.ant-tree-directory>li.ant-tree-treenode-selected>span.ant-tree-node-content-wrapper::before,
	/deep/.ant-tree.ant-tree-directory>li span.ant-tree-node-content-wrapper.ant-tree-node-selected {
		background: #f7f8fa;
		color: @cq_color;
	}

	/deep/.ant-tree.ant-tree-directory>li span.ant-tree-node-content-wrapper.ant-tree-node-selected {
		background: #f7f8fa;
		color: @cq_color;
	}

	/deep/.ant-tree.ant-tree-directory .ant-tree-child-tree>li.ant-tree-treenode-selected>span.ant-tree-node-content-wrapper::before {
		background: #f7f8fa;
		color: @cq_color;
	}

	/deep/.ant-row {
		margin-bottom: 12px;
	}

	.FieldSettingsBox {

		/deep/.anticon {
			font-size: 20px;
			color: #c0dffe;
			margin-right: 5px;
		}
	}

	/deep/.ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon {
		color: #666;
		font-size: 16px;
	}

	/deep/.ant-tree.ant-tree-directory>li.ant-tree-treenode-selected>span.ant-tree-switcher {
		color: #333;
	}

	/deep/.ant-tree li span.ant-tree-switcher.ant-tree-switcher_open {
		color: #333;
		font-size: 16px;
	}

	/deep/.ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-icon,
	/deep/.ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-select-switcher-icon {
		color: #333;
		font-size: 16px;
	}
	.tips_top_box{
		margin-bottom:12px;
	}
	/deep/.page_content{
		padding:0px;
		margin:0px;
	}
</style>