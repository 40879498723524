<template>
	<div>
   <a-alert  type="error" closable v-if='is_error_key'>
      <p slot="description">
       部分表格内容无法导入，您可以导出这些内容，根据提示信息修改后重新上传。
       <span class='cq_color' @click='down_link'>下载错误信息</span>
      </p>
    </a-alert>
		<!-- <a-alert closable  show-icon type="error" >
			<template #description>
				<p>
				
				</p>
			</template>
		</a-alert> -->
		<div :class="is_error_key?'mar_30':'' ">
			<a class="ds-modal-upload_btn">
				<span>下载标准模板</span> <span @click='exportTemplate'
					class="ds-modal-upload_btn-sub">（适合首次新增没有标准模板）</span></a>

		</div>

		<div class="mar_30">
			<a-upload-dragger name="file" :customRequest="handle_custom_request" :before-upload="handle_before_upload"
				@change="handleChange" @drop="handleDrop">
				<p>
					<a-icon type="upload" />
				</p>

				<p class="ant-upload-text">拖拽或点击上传</p>
				<p>仅支持格式：xls/xlsx</p>
			</a-upload-dragger>
		</div>




	</div>
</template>

<script>

	import {
		batchAddLocation,
		batchAddRole
	} from '@/api/organization.js'
	import {
		upload
	} from '@/api/public'
	import {
		message
	} from 'ant-design-vue';
	import moment, {
		Moment
	} from 'moment';
	export default {
		props: ['type'],
		components: {
		},
		data() {
			return {
				is_error_key: false,
				visibleDrawer: false,
				activeKey: 0,
				percent: 90,
				visibleWIFI: false,
				defaultExpandAll: true,
				treeData: [],
				selectArr: [], //选中的值

			}
		},
		created() {},

		methods: {
			onClose(){
				this.is_error_key=''
			},
			down_link() {
				this.$method.handleExport({
					error_key: this.is_error_key
				}, '/error/error_download/location')
			},
			//导出模板
			exportTemplate() {
				let a = document.createElement('a');
				a.href = this.$config.host+'/static/excel/批量新增工作地点模板.xlsx'
				a.target = '_blank'
				a.click();
				message.success('导出成功!')

			},

			type_impoirt(filename) {
				this.is_error_key = ''
				batchAddLocation({
					data: {
						file_name: filename
					},

				}).then(res => {
					console.log(res)
					if (res.data.is_error) {
						console.log(1)
						this.is_error_key = res.data.error_key
					} else {
						message.success('导入成功')
						this.percent = 100;
						this.visibleWIFI = false
						this.$emit('success')
					}

				})
			},
			handleDrop(e) {
				this.visibleWIFI = true
				let that = this
				this.$req({
					mode: 'file',
					url: '/manage/api.plugs/upload',
					data: {
						file: file.file,
					}
				}).then(res => {
					that.type_impoirt(res.data.upload_data.filename)
					
				}).catch(e => {
					this.visibleWIFI = false
				})
			},
			handle_custom_request(file) {
				let that = this
				this.visibleWIFI = true
				// upload({
				// 	data: {
				// 		file: file.file,
				// 	},
				// 	toast: false
				// }).then(res => {
				// 	this.type_impoirt(res.upload_data.filename)

				// }).catch(res => {
				// 	this.visibleWIFI = false
				// })
				
				this.$req({
					mode: 'file',
					url: '/manage/api.plugs/upload',
					data: {
						file: file.file,
					}
				}).then(res => {
					that.type_impoirt(res.data.upload_data.filename)
					
				}).catch(e => {
					this.visibleWIFI = false
				})
			},
		},
		computed: {

		},

	}
</script>

<style lang="less" scoped="scoped">
	@import url("../../assets/less/app.less");
	.mac_input {
		width: 60px;

		display: inline-block;
	}

	.dot_line {
		width: 10px;
		display: inline-block;
		text-align: center;
	}

	.parent_view {
		background: #fff;
		padding: 12px;
		margin: 12px;
	}

	.upload {
		padding: 30px;
		height: 400px;
		width: 80%;
		margin: 0 auto;
	}

	.tips_info {
		padding: 30px;
		width: 80%;
		margin: 0 auto;
	}

	/deep/.ant-upload-list {
		display: none;
	}

	.tips_top_box {
		font-size: 14px;

		.title {
			font-size: 16px;
			font-weight: 600;
			color: #333;
			margin-bottom: 20px;
			margin-top: -20px;
		}

		p {
			line-height: 26px;
			color: #80848f;
		}

		margin-bottom: 30px;
	}

	.tips_ber p:before {
		position: relative;
		top: -3px;
		display: inline-block;
		width: 5px;
		height: 5px;
		margin-right: 5px;
		background: #80848f;
		border-radius: 50%;
		content: "";
	}



	.line_30 {
		line-height: 60px;
	}

	.mar_30 {
		margin-top: 24px;
	}

	.ds-modal-upload_btn {
		display: block;
		padding: 0 24px;
		color: #43464d;
		line-height: 40px;
		border: 1px solid #e9eaec;
		border-radius: 4px;
		box-shadow: 0 0 10px rgba(73, 80, 96, .1)
	}

	.ds-modal-upload_btn:hover {
		border-color: @cq_color;
	}

	.ds-modal-upload_btn:hover,
	.ds-modal-upload_btn:hover .ds-modal-upload_btn-icon {
		color: @cq_color;
	}

	.footer_BD946 {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		background: #fff;
		box-shadow: 0 -2px 16px rgba(0, 0, 0, 0.1);
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 12px 0px;
	}
	/deep/.ant-upload.ant-upload-drag .ant-upload{
		padding: 60px 0;
	}
</style>